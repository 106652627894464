/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ALLOWED_TASK_TYPE {
  checkpoint = "checkpoint",
  codeRun = "codeRun",
  file = "file",
  image = "image",
  multipleChoice = "multipleChoice",
  supportingImage = "supportingImage",
  supportingVideo = "supportingVideo",
  supportingWebpage = "supportingWebpage",
  text = "text",
  video = "video",
  whiteboard = "whiteboard",
}

export enum ALLOWED_TYPE {
  checkpoint = "checkpoint",
  code = "code",
  codeRun = "codeRun",
  file = "file",
  iframe = "iframe",
  image = "image",
  task = "task",
  text = "text",
  video = "video",
}

export enum CodeRunEventType {
  ack = "ack",
  error = "error",
  keyevent = "keyevent",
  keylisten = "keylisten",
  run = "run",
  running = "running",
  starting = "starting",
  stderr = "stderr",
  stdin = "stdin",
  stdout = "stdout",
  stop = "stop",
  stopped = "stopped",
}

export enum CodeRunKeyEventType {
  keydown = "keydown",
  keyup = "keyup",
}

export enum CoderConnectionEventType {
  connect = "connect",
  disconnect = "disconnect",
}

export enum HIT_TYPE {
  course = "course",
  project = "project",
}

export enum HardwarePreference {
  pi_top_3 = "pi_top_3",
  pi_top_4 = "pi_top_4",
  pi_top_ceed = "pi_top_ceed",
  pt4_DIY = "pt4_DIY",
  pt4_expansion_plate = "pt4_expansion_plate",
  pt4_foundation_plate = "pt4_foundation_plate",
}

export enum MembershipType {
  EXPIRED = "EXPIRED",
  FULL = "FULL",
  NONE = "NONE",
  TRIAL = "TRIAL",
}

export enum NotificationType {
  ADD_ASSIGNMENT_ON_CLASS = "ADD_ASSIGNMENT_ON_CLASS",
  ADD_COMMENT_ON_PROJECT = "ADD_COMMENT_ON_PROJECT",
  ADD_COMMENT_ON_SUBMISSION = "ADD_COMMENT_ON_SUBMISSION",
  ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY",
  ADD_GROUP_ADMIN = "ADD_GROUP_ADMIN",
  ADD_GROUP_MEMBER = "ADD_GROUP_MEMBER",
  ADD_SUBMISSION_ON_ASSIGNMENT = "ADD_SUBMISSION_ON_ASSIGNMENT",
  EDIT_PROJECT = "EDIT_PROJECT",
  EDIT_SUBMISSION = "EDIT_SUBMISSION",
}

export enum PRICE_INTERVAL {
  day = "day",
  month = "month",
  week = "week",
  year = "year",
}

export enum ProjectType {
  assessment = "assessment",
  challenge = "challenge",
}

export enum SCHOOL_CLASS_MEMBER_ROLE {
  student = "student",
  teacher = "teacher",
}

export enum SCHOOL_MEMBER_ROLE {
  admin = "admin",
  student = "student",
  teacher = "teacher",
}

export enum SUBSCRIPTION_STATUS {
  active = "active",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  paused = "paused",
  trialing = "trialing",
  unpaid = "unpaid",
}

export enum TEACHER_TRAINING_TYPE {
  onboarding = "onboarding",
}

export enum UserType {
  HOME = "HOME",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum VISIBILITY_STATES {
  private = "private",
  public = "public",
  unlisted = "unlisted",
}

export interface CodeRunEventDataInput {
  sourcePath?: string | null;
  path?: string | null;
  code?: string | null;
  exitCode?: number | null;
  output?: string | null;
  input?: string | null;
  message?: string | null;
  codeRunId?: string | null;
  key?: string | null;
  event?: CodeRunKeyEventType | null;
}

export interface CoderFileInput {
  name: string;
  code: string;
  sourceCrossVersionId: string;
}

export interface MemberData {
  email?: string | null;
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
}

export interface SchoolClassMemberInput {
  schoolMemberId: string;
  role: SCHOOL_CLASS_MEMBER_ROLE;
}

export interface SchoolMemberInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  role: SCHOOL_MEMBER_ROLE;
}

export interface SchoolMemberResetPasswordInput {
  schoolMemberId: string;
  password: string;
}

export interface TranslationSearchInput {
  language: string;
  region?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
