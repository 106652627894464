import { ApolloClient, createHttpLink, split, from } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { OperationDefinitionNode } from 'graphql';

import { apiBaseUrl } from '../services/apiBaseUrl';
import { logLink, ptRequestIdLink, WebSocketLink } from './links';
import keycloak, { ready as keycloakIsReady } from '../keycloak';
import createCache from './createCache';
import setupPersistedCache from './setupPersistedCache';
import { link as networkStatusLink } from './networkStatus';

const orderedMutationNames = [
  'SendCodeRunEvent',
  'EditMultipleChoiceUpdateTaskDetail',
];

function isOrderedMutation(definition: OperationDefinitionNode) {
  return !!(
    definition.operation === 'mutation' &&
    definition.name &&
    orderedMutationNames.includes(definition.name?.value)
  );
}

const batchHttpLink = new BatchHttpLink({ uri: `${apiBaseUrl}/graphql` });
const httpLink = createHttpLink({ uri: `${apiBaseUrl}/graphql` });

const createAuthLink = ({ cacheIsReady }: CreateLinkOptions) =>
  setContext(async (_, { headers }) => {
    await keycloakIsReady;
    await cacheIsReady;

    const accessToken = keycloak.token;

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

const [baseProtocol, baseUrl] = apiBaseUrl.split('://');
const wsProtocol = baseProtocol === 'https' ? 'wss' : 'ws';

const createWebsocketLink = ({ cacheIsReady }: CreateLinkOptions) =>
  new WebSocketLink({
    url: `${wsProtocol}://${baseUrl}/subscriptions`,
    connectionParams: async () => {
      await keycloakIsReady;
      await cacheIsReady;

      return { accessToken: keycloak.token };
    },
  });

const link = ({
  batchHttpRequests = true,
  useOrderedMutations = true,
  useAuthLink = true,
  cacheIsReady,
}: CreateLinkOptions = {}) =>
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        (definition.operation === 'subscription' ||
          (useOrderedMutations && isOrderedMutation(definition)))
      );
    },
    from([networkStatusLink, createWebsocketLink({ cacheIsReady })]),
    from([
      ...(useAuthLink ? [createAuthLink({ cacheIsReady })] : []),
      ptRequestIdLink,
      logLink,
      networkStatusLink,
      split(() => batchHttpRequests, batchHttpLink, httpLink),
    ])
  );

type CreateClientOptions = {
  batchHttpRequests?: boolean;
  onCacheReady?: () => void;
  useOrderedMutations?: boolean;
  useAuthLink?: boolean;
  persistCache?: boolean;
};

type CreateLinkOptions = {
  batchHttpRequests?: boolean;
  cacheIsReady?: Promise<void>;
  useOrderedMutations?: boolean;
  useAuthLink?: boolean;
};

const createClient = (options: CreateClientOptions = {}) => {
  const cache = createCache();
  const cacheIsReady = options.persistCache
    ? setupPersistedCache(cache)
    : Promise.resolve();

  cacheIsReady
    .then(() => options.onCacheReady && options.onCacheReady())
    .catch(() => options.onCacheReady && options.onCacheReady());

  return new ApolloClient({
    link: link({ ...options, cacheIsReady }),
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });
};

export const createIntegrationTestClient = (
  options: CreateClientOptions = {}
) =>
  createClient({
    ...options,
    persistCache: false,
  });

export const createUnitTestClient = (options: CreateClientOptions = {}) =>
  createClient({
    ...options,
    batchHttpRequests: false,
    useOrderedMutations: false,
    persistCache: false,
  });

export default createClient;
