/* eslint no-underscore-dangle: 0, vars-on-top: 0, no-var: 0, prefer-destructuring: 0 */

import ReactGA from 'react-ga';

window._hsq = window._hsq || [];
var _hsq = window._hsq;

export default function track(id, data) {
  _hsq.push(['trackEvent', { ...data, id }]);

  if (window.Intercom) {
    window.Intercom('trackEvent', id, data); // eslint-disable-line
  }
}

export const trackPageView = (pathname) => {
  _hsq.push(['setPath', pathname]);
  _hsq.push(['trackPageView']);

  ReactGA.set({ page: pathname }); // Update the user's current page
  ReactGA.pageview(pathname); // Record a pageview for the given page
};

export const trackUser = ({
  id,
  email,
  firstName,
  lastName,
  realm,
  userType,
  ...rest
}) => {
  if (userType && userType.toUpperCase() !== 'STUDENT') {
    _hsq.push([
      'identify',
      {
        email,
        id,
        firstName,
        lastName,
        further_realm: realm,
        userType,
        ...rest, // usertype, nomarketing, membershiptype, hardwarepreferences
      },
    ]);
  }

  if (window.Intercom && userType) {
    window.Intercom('update', {
      // eslint-disable-line
      user_id: id,
      email,
      name: `${firstName} ${lastName}`,
      realm,
      userType,
      ...rest, // userType, noMarketing, membershipType, hardwarePreferences
    });
  }

  ReactGA.set({
    userId: id,
  });
};
