import { gql } from '@apollo/client';

/*
Since this is used across different task types, we should not include sensitive 
fields such as correctChoice or correctChoices. Those should be requested
conditionally by the multiple choice task detail components.
*/
export default gql`
  fragment DetailContentFields on DetailContent {
    id
    url
    youtubeId
    wistiaId
    height
    sampleCode
    coderFileId
    choices
    hasSingleCorrectChoice
    initialWhiteboard
    noBackgroundRender
    aspectRatio
    fullscreenDisabled
  }
`;
